<template>
  <div class="search">
    <div class="search_img">
      <img src="../../assets/img/search2x.png" alt />
      <div class="search_title">搜索</div>
      <el-input
        class="search_input"
        placeholder="请输入内容"
        suffix-icon="el-icon-search"
        @input="searchbtn"
        v-model="ruleForm.search"
      >
        <span v-if="ruleForm.search!=''" slot="suffix" class="search_text">{{search_count}}条搜索结果</span>
      </el-input>
    </div>

    <div class="search_data" v-if="isDetails==false">
      <div class="search_dataItems">
        <div
          class="search_data_item"
          v-for="item in search_data"
          :key="item"
          @click="LookDetail(item.id)"
        >
          <div class="search_data_img">
            <img style="width:100%" :src="item.img" alt srcset />
          </div>

          <div class="search_data_content">
            <div class="search_data_title">{{item.title}}</div>
            <span class="search_data_title_style" style>{{item.issceTime}}</span>
            <div class="search_data_line"></div>

            <div class="search_data_text">{{item.summary}}</div>

            <div class="CompanyNews_content_right_style" style="position: absolute;">
              <span class="CompanyNews_item_lookbtn">查看更多</span>
            </div>
          </div>
        </div>

        <div class="pagination-container" v-if="search_data.length!=0">
          <el-pagination
            class="pagination_style"
            style="text-align: right;"
            background
            layout="total, sizes,prev, pager, next,jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="ruleForm.current"
            :page-size="ruleForm.pageSize"
            :page-sizes="[10, 15, 20]"
            :total="search_count"
          ></el-pagination>
        </div>
      </div>

      <div class="search_null" v-if="search_data.length==0">
        <img src="../../assets/img/search_none.png" alt />
        <div>暂无结果，请尝试不同的关键词！</div>
      </div>
    </div>

    <company-news_details v-else :id="details_id" class="Operating_Period_Projects"></company-news_details>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { getJoList } from "@/api/api";
import CompanyNews_details from "../components/CompanyNews_details.vue";
export default {
  name: "search",
  data() {
    return {
      ruleForm: {
        current: 1,
        pageSize: 10,
        search: ""
      },
      isDetails: false,
      search_val: "",
      search_data: [],
      search_count: 0
    };
  },
  components: { CompanyNews_details },
  created() {},
  mounted() {
    this.$store.commit("updateTab", 6);
  },
  methods: {
    getJoList() {
      getJoList(this.ruleForm)
        .then(res => {
          console.log(res.data);
          this.search_data = res.data.retData;
          this.search_count = res.data.total;
          this.isDetails = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    searchbtn() {
      this.ruleForm.current = 1;
      this.ruleForm.pageSize = 10;

      this.getJoList();
    },

    // 判断元素距离窗口的位置
    isElemVisible(el) {
      var rect = el.getBoundingClientRect();
      var elemTop = rect.top + 200; // 200 = buffer
      var elemBottom = rect.bottom;
      return elemTop < window.innerHe, ight && elemBottom >= 0;
    },
    handleSizeChange(val) {
      this.ruleForm.current = 1;
      this.ruleForm.pageSize = val;
      this.getJoList();
    },
    handleCurrentChange(val) {
      this.ruleForm.current = val;
      this.getJoList();
    },
    LookDetail(id) {
      this.isDetails = false;
      this.$nextTick(function() {
        this.details_id = id;
        this.isDetails = true;
      });
    }
  }
};
</script>
<style lang="scss">
$vm_w_base: 1920;
$vm_h_base: 1080;
@function vm_w($px) {
  @return ($px / 1920) * 100vw;
}
.fade-in {
  width: 100%;
  height: 100%;
  opacity: 0;
  filter: alpha(opacity = 0);
  filter: progid:DXImageTransform.Microsoft.Alpha(style = 0, opacity = 0);
  transition: 0.5s all ease-out;
  -webkit-transition: 0.5s all ease-out;
  -moz-transition: 0.5s all ease-out;
  -ms-transition: 0.5s all ease-out;
  -o-transition: 0.5s all ease-out;

  transform: scale(0.8);
  box-sizing: border-box;
}
.Operating_Period_Projects {
  width: vm_w(1200);
  padding-bottom: vm_w(64);
  margin: 0 auto;
}

.search {
  width: 100%;
}
.search_img {
  width: 100%;
  height: vm_w(376);
  position: relative;
}
.search_img img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.search_input {
  position: absolute;
  width: vm_w(640) !important;
  top: 50%;
  font-size: vm_w(14) !important;
  left: calc(50% - #{vm_w(320)}) !important;
  left: -webkit-calc(50% - #{vm_w(320)}) !important;
  left: -moz-calc(50% - #{vm_w(320)}) !important;
  left: -ms-calc(50% - #{vm_w(320)}) !important;
  left: -o-calc(50% - #{vm_w(320)}) !important;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.search_text {
  margin-right: vm_w(45);
}

.search_data {
  width: 100%;
  min-height: vm_w(165);
}
.search_null {
  width: vm_w(419);
  margin: 0 auto;
  & div {
    text-align: center;
    font-size: 14px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    margin-top: 6px;
    margin-bottom: 87px;
    color: #999999;
  }
}
.CompanyNews_content_right_style {
  bottom: vm_w(5);
}
.pagination_style {
  margin-top: vm_w(10);
  margin-bottom: vm_w(10);
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: $chooseColor !important;
}
.search_null img {
  width: 100%;
  margin-top: 69px;
}
.search_dataItems {
  width: vm_w(1192);
  padding-top: vm_w(20);

  margin: 0 auto;
}
.search_data_img {
  width: vm_w(242);
  height: vm_w(172);
  display: inline-block;
  vertical-align: top;
}
.search_data_img img {
  width: 100%;
  height: 100%;
}
.CompanyNews_item_lookbtn {
  font-size: vm_w(14);
  font-family: Source Han Sans CN, Source Han Sans CN-Normal;
  color: #333333;
}
.search_data_content {
  width: vm_w(858);
  height: vm_w(172);
  margin-left: vm_w(32);
  position: relative;
  display: inline-block;
}
.search_data_title {
  margin-top: vm_w(8);
  font-size: vm_w(18);
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
  font-weight: bold;
  text-align: left;
  color: #333333;
  width: vm_w(658);
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search_data_line {
  margin-top: vm_w(16);
  width: vm_w(172);
  height: vm_w(4);
  background: #999999;
}
.search_data_title_style {
  float: right;
  font-weight: 400;
  display: inline;
  font-size: vm_w(14);
  line-height: vm_w(18);
  margin-top: vm_w(10);
  color: #999999;
}

.search_data_text {
  margin-top: vm_w(12);
  width: 100%;
  font-size: vm_w(14);
  font-family: Source Han Sans CN, Source Han Sans CN-Normal;
  text-align: left;
  color: #666666;
  line-height: vm_w(24);
  overflow: hidden;
  height: vm_w(70);
}
.search_data_text:before {
  content: "";
  float: left;
  width: vm_w(5); /*缩小宽度为5px，其余属性不变*/
  height: vm_w(40);
}

.search_data_text > :first-child {
  float: right;
  width: 100%;
  margin-left: vm_w(
    -5
  ); /*让main元素左移5px，这样main元素在宽度上就完全占满了父元素；*/
  word-break: break-all;
  box-sizing: border-box;
  text-align: justify;
}
.search_data_text:after {
  content: "...";
  box-sizing: content-box;
  float: right;
  position: relative;
  width: vm_w(40);
  font-size: vm_w(14);
  height: vm_w(24);
  top: vm_w(-24); /*等于高度的负值，就是文字的行高*/
  right: 0;
  /*设置margin-left。padding-right。则是为了让realend元素的盒模型的最终宽度计算为5px。*/
  margin-left: vm_w(-11);
  padding-right: vm_w(5);
  text-align: left;
  background: #fff; /* W3C */
}

.lookmore_btn {
  font-size: vm_w(14);
  color: #333 !important;
}

.search_data_item {
  box-sizing: border-box;
  padding: vm_w(32) vm_w(16);
  border-bottom: solid vm_w(1) #f5f5f5;
  cursor: pointer;
}
.search_data_item:hover {
  background-color: #f5f5f5;
}
.search_data_item:hover .search_data_content {
  color: $chooseColor;
}
.search_data_item:hover .search_data_title {
  color: $chooseColor;
}
.search_data_item:hover .search_data_line {
  background-color: $chooseColor;
}
.search_data_item:hover .search_data_text {
  color: $chooseColor;
}
.search_data_item:hover .CompanyNews_item_lookbtn {
  text-decoration: underline;
  color: $chooseColor !important;
}
.search_data_item:hover .search_data_text:after {
  background-color: #f5f5f5;
}

.search_title {
  position: absolute;
  font-size: vm_w(18);
  font-family: Source Han Sans CN, Source Han Sans CN-Bold;
  font-weight: 700;
  color: #333333;
  top: vm_w(118);
  left: calc(50% - #{vm_w(320)});
  left: -webkit-calc(50% - #{vm_w(320)});
  left: -moz-calc(50% - #{vm_w(320)});
  left: -ms-calc(50% - #{vm_w(320)});
  left: -o-calc(50% - #{vm_w(320)});
}
</style>