
import { get, post, DELETE, put } from '@/utils/request';

export const getIndexB = (body) => get("/cms/cms-advertising/img-url", body)
export const getJoList = (body) => get("/cms/cms-journalism/list", body)

export const getJoId = (id) => get("/cms/cms-journalism/get/" + id)

export const getReList = () => get("/hr/hr-recruit/list")

export const submitFb = (body) => post("/question/question-feedback/save", body)

export const submitPc = (body) => post("/srm/srm-organization/saveOrganization", body)

export const sendAuthCode = (body) => post("/srm/srm-organization/sendAuthCode", body)


export const submitSu = (body) => post("/srm/srm-supplier/saveSupplier", body)

export const suSendAuthCode = (body) => post("/srm/srm-supplier/sendAuthCode", body)

export const valuesByCategory = (body) => get("/sys/sys-dictionary/valuesByCategory", body)
export const valuesByPid = (body) => get("/sys/sys-dictionary/valuesByPid", body)
// export const valuesByPid = (body) => get("/system/dict/data/type/", body)
